import styled from "styled-components";
 
export const Box = styled.div`
    padding: 5% 2.5%;
    background: var(--black);
    position: absolute;
    left: 0;
    width: 95%;
 
    @media (max-width: 1000px) {
        // padding: 70px 30px;
    }
`;
 
export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* background: red; */
`;
 
export const Column = styled.div`
    
    display: flex;
    flex-direction: column;
    text-align: left;
    
`;
 
export const Row = styled.div`
    
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    @media (max-width: 1000px) {
        grid-template-columns: repeat(
            auto-fill,
            minmax(200px, 1fr)
        );
    }
`;
 
export const FooterLink = styled.a`
    color: #fff;
    margin-bottom: 1rem;
    font-size: 1rem;
    text-decoration: none;
 
    &:hover {
        color: var(--secondary);
        transition: 200ms ease-in;
    }
`;
 
export const Heading = styled.p`
    font-size: 1.4rem;
    color: #fff;
    margin-bottom: 2rem;
    font-weight: bold;
`;