import React from 'react';
import "./Bynumbers.css";
import hotfood from "../../../../images/hot-food.png";
import guests from "../../../../images/customer.png";
import delivery from "../../../../images/fast-delivery.png";

const StatItem = ({ icon, value, label }) => (
    <div className="stat-item">
      <img src={icon} alt="stat_icon" className="stat-icon" />
      <div className="stat-content">
        <div className="stat-value numberFont">{value}</div>
        <div className="stat-label">{label}</div>
      </div>
    </div>
  );
  
  const statsData = [
    {
      icon: delivery,
      value: "5000+",
      label: "Kiszállított rendelés",
    },
    {
      icon: guests,
      value: "500+",
      label: "Visszatérő vendég",
    },
    {
      icon: hotfood,
      value: "50+",
      label: "Ételkínálat",
    },
  ];
  
  function Bynumbers() {
    return (
      <>
        <section className="stats-container">
          {statsData.map((stat, index) => (
            <StatItem
              key={index}
              icon={stat.icon}
              value={stat.value}
              label={stat.label}
            />
          ))}
        </section>
      </>
    );
  }
  

export default Bynumbers;