import React, { useEffect } from 'react';
import "./MenuPage.css";
import logo from "../../images/logo.png";
import { useState } from 'react';
import MenuItem from './MenuItem/MenuItem';
import TabNavigation from './Tab/MobileTab';
import InfoContainer from './InfoContainer/InfoContainer';
import Navbar from '../landing/navbar/Navbar';



function MenuPage({data}) {
    const [renderedMenu, setRenderedMenu] = useState(data.categories[0]);
    const [selectedTab, setSelectedTab] = React.useState(data.categories[0].name);

    // Function to update the parent state
    const updateRenderedMenu = (newState) => {
        setRenderedMenu(newState);
    };

      

    return ( 
        <>
            <div className="navbarmenu">
                <Navbar hideLogo={true}/>
            </div>
            
            <div className="sidebar">
            
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="menu">
                    <ul>
                        {data.categories.map((category, index) => (
                            <li 
                                key={index} 
                                onClick={() => updateRenderedMenu(category)}
                                className={category === renderedMenu ? "active" : ""}
                            >
                                {category.name}
                            </li>
                            
                        ))}
                    </ul>
                </div>
                <div className="footer">
                    <p>Rendelés:</p>
                    <p href="tel:+36203994361" className='numberFont'>+36 20 399 4361</p>
                </div>
            </div>
            <div className="sidebar-mobile">
                <TabNavigation categories={data.categories} />    
                <div className="footer-mobile">
                    <p>Rendelés:</p>
                    <a href="tel:+36203994361" className='numberFont'>+36 20 399 4361</a>
                    <div>Copyright @ 2024 Terasz Büfé, minden jog fenntartva.</div>
                </div>
            </div>
            <div className="menuitems-container">
                {renderedMenu.name === "Pizzák" ? (
                    <InfoContainer isPizza={true}/>
                ):null}
                {renderedMenu.name === "Családi menük" ? (
                    <InfoContainer isPizza={false}>
                        A családi menüben feltüntetett desszertről érdeklődjön a megadott telefonszámon!
                    </InfoContainer>
                ):null}
                {renderedMenu.name === "Heti menük" ? (
                    <InfoContainer isPizza={false}>
                        A menü ára helyben fogyasztás esetén <span className='numberFont highlight' style={{fontWeight:'bold', color:'var(--primary)'}}>1950 FT</span>, kiszállítás esetén <span className='numberFont' style={{fontWeight:'bold', color:'var(--primary)'}}>2050 FT</span>
                    </InfoContainer>
                ):null}
                <div className="menuitems-header">
                   { renderedMenu ? (
                        <div className='itemheader-content'>
                            <h3>{renderedMenu.name}</h3>

                            { renderedMenu.name === "Pizzák" ? (
                                <div className="sizes">
                                    <span>30cm</span>
                                    <span>50cm</span>
                                </div>
                            ) : null}
                             { renderedMenu.name === "Sültes tálak" ? (
                                <div className="sultesholder-desktop">
                                    <span className='numberFont'>2 fő</span>
                                    <span className='numberFont'>4 fő</span>
                                </div>
                            ) : null}
                        </div>
                   ) : null}
                </div>
                <div className="menuitems-content">
                    {renderedMenu ? (
                        renderedMenu.foods.map((food, index) => (
                             <MenuItem key={index} index={index+1} itemname={food.name} ingredients={food.ingredients} price={food.price} price_2={food.price_2}/>
                            ))
                    ) : null}
                </div>
            </div>     
        
        </>
     )
}

export default MenuPage;