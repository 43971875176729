import React from 'react';
import "./Hero.css";
import Bynumbers from './bynumbers/Bynumbers';

import heroimg from "../../../images/hero_pizza_new2.png";
import coffeimg from "../../../images/coffe-Kittl.svg";
import onionimg from "../../../images/onion-Kittl.svg";
import carrotimg from "../../../images/carrot.png";
import leafimg from "../../../images/leaf.png";

import { Link } from 'react-router-dom';

function Button({ children, className }) {
    return <button className={`button ${className}`}>{children}</button>;
  }

  
  function Hero() {

    const scrollToTarget = (event, targetId) => {
      event.preventDefault();
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
      <>
        <main className="main-content">
       
          <div className="columns">
            <div className="content-wrapper">
              <section className="text-column">
                <div className="text-content">
                  <h1 className="title">
                    <span>Fedezd fel a </span>
                    <span className="highlight">Terasz Büfé</span>
                    <span> különleges ízeit.</span>
                  </h1>
                  <p className="description">
                    Friss reggelire, kávéra, ebédre vagy finom pizzára vágysz?
                    Nálunk biztos, hogy megtalálod azt, amit kívánsz!
                  </p>
                  <div className="button-group">
                  <Link to="/menu">
                    <button className='button button-primary orderbtn' >Étlapunk</button>
                  </Link>
                  <button className='button button-secondary aboutbtn'  onClick={(e) => scrollToTarget(e, 'scroll-about')} >Rólunk</button>
                  </div>
                </div>
              </section>
              
              <section className="image-column">
                  <img className ="coffepic" src={coffeimg} alt="coffe" loading="lazy"/>
                  <img className ="onionpic" src={onionimg} alt="onion" loading="lazy"/>
                  <img className ="carrotimg" src={carrotimg} alt="carrot" loading="lazy"/>
                  <img className ="leafimg" src={leafimg} alt="leaf" loading="lazy"/>
                <img
                  src={heroimg}
                  alt="Terasz Büfé"
                  className="featured-image"
                />
              </section>
            </div>
            <Bynumbers/>
          </div>
          
        </main>
      </>
    );
  }

  
export default Hero;