import React from 'react';
import "./About.css";
import aboutpic from "../../../images/aboutpic.png";

function About() {
    return ( 
        <>
        <h1 className="about-header" id='scroll-about'>Rólunk</h1>
        <div className="about-container" >
            <div className="about-img">
                <img className="imgab" src={aboutpic} alt="about" loading='lazy'/>
            </div>
            
            <div className="about-content" >
            <h2 className="welcometext">Kedves Olvasó!</h2>
                <div className="about-texts">
               
                <p>Üdvözlünk a  <span className="about-highlight">Terasz Büfé és Pizzéria világában</span>, ahol a fiatalos lendület, a kreativitás és a vendégközpontúság találkozik. Csapatunk rugalmasságával és dinamizmusával törekszik arra, hogy minden vendégünk számára <span className="about-highlight">felejthetetlen</span> gasztronómiai élményt nyújtson.</p>
                <p>Büszkén kínálunk több, mint <span className="about-highlight">100 féle</span> ételt, kezdve a friss, ropogós reggelikkel, ízletes heti menükkel, desszertekkel, egészen a különleges, kézzel készített pizzákig.</p>
                <p>Ami a pizzákat illeti, nálunk a hagyományos ízek mellett belevághatsz <span className="about-highlight">gluténmentes</span>, <span className="about-highlight">laktózmentes</span> vagy épp egy szuper <span className="about-highlight">teljes kiörlésű fitness pizza</span> kalandjába is. </p>
                <p><span className="about-highlight">Sültes táljaink</span> a hagyományos magyar ízeket és az új, izgalmas ízkombinációkat ötvözik, garantálva ezzel, hogy mindenki megtalálja a számára tökéletes ételkülönlegességet.</p>
                <p>Ne aggódj, ha nem tudsz eljönni hozzànk, futárjaink <span className="about-highlight">házhoz viszik a rendeléseiteket.</span> </p>
                <p>Látogass el hozzánk, és tapasztald meg ízeinket,  a meleg vendégszeretet tökéletes harmóniáját! Izgalmas ízutazásra invitálunk Pakson, ahol a hagyomány és az újítás találkozik.</p>
                </div>
                <div>
                    <h2>Várunk a Teraszban!</h2>
                    <span>~ a Terasz Büfé csapata</span>
                </div>
            </div>
        </div>
        </>
     );
}

export default About;