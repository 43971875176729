import React from 'react';
import "./Menu.css";
import { Link } from 'react-router-dom';

import openbook from "../../../images/open-book.png";

const DeliveryInfo = ({ location, price }) => (
    <div className="delivery-info">
      <div className="delivery-location">{location}</div>
      <div className="delivery-price">
        <span className="numberFont" style={{ color: "rgba(41,56,27,1)" }}>{price}</span>
      </div>
    </div>
  );
  
  const deliveryData = [
    { location: "Paks, erőmű:", price: "INGYENES" },
    { location: "Dunaszentgyörgy:", price: "1500 FT" },
    
    { location: "Erőmű horgásztó:", price: "800 FT" },
    { location: "Pörös, Biritópuszta, Csámpa:", price: "600 FT" },
    { location: "Bölcske:", price: "2200 FT" },
    { location: "Dunakömlőd:", price: "1000 FT" },
    
    { location: "Fadd, Gerjen, Nagydorog, Viza Bisztró", price: "2000 FT" },
    { location: "Gyapa, Cseresznyés:", price: "1200 FT" },
    { location: "Györköny, Madocsa, Németkér, Pusztahencse:", price: "1600 FT" },
    { location: "Tengelic-Szőlőhegy:", price: "2500 FT" },
  ];

  
  function Menu() {
    return (
      <>
        <section className="delivery-section" id="scroll-order">
          <h1 className="title-menu">A Legfinomabb ételek várnak!</h1>
          <p className="description-menu">
            Böngészd végig étlapunkat, a kiválasztott ételt pedig kivisszük neked!
          </p>
          <p className="order-info">
            Rendelés a{" "}
            <span className="numberFont" style={{ fontWeight: 700, color: "rgba(41,56,27,1)" }}>
              +36 20 399 4361
            </span>
            {" "}telefonszámon!
          </p>
          
          <h2 className="delivery-title">Házhozszállítás:</h2>
          <div className="delivery-container">
            <div className="delivery-columns">
              <div className="delivery-column">
                <div className="delivery-info-container">
                  {deliveryData.slice(0, 6).map((item, index) => (
                    <DeliveryInfo
                      key={index}
                      location={item.location}
                      price={item.price}
                    />
                  ))}
                </div>
                
              </div>
              <div className="delivery-column">
                <div className="delivery-info-container">
                  {deliveryData.slice(6).map((item, index) => (
                    <DeliveryInfo
                      key={index}
                      location={item.location}
                      price={item.price}
                    />
                  ))}
                </div>
              </div>
              <div className="delivery-column img-col">
                <img
                  loading="lazy"
                  src={openbook}
                  className="delivery-image"
                  alt="Delivery illustration"
                />
              </div>
            </div>
            <div className="menubtn-container">
              <Link to="/menu">
                <button className="button-primary menu-button">Étlap megtekintése</button>
              </Link>
            </div>
            
          </div>
        </section>
  
      </>
    );
  }

  export default Menu;