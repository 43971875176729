import React from 'react';
import "./Reviews.css";

import StarIcon from "../../../images/star.png";
import ProfilePic from "../../../images/profile-user.png";
import QuoteIcon from "../../../images/quote.png";
import pizzaimg from "../../../images/pizzaicon.png";


function ReviewCard({reviewtext, author}) {
    const stars = [1, 2, 3, 4, 5];
    return (
      <>
        <section className="testimonial" id="scroll-reviews">
          <article className="testimonial-card">
            <div className="star-rating">
              {stars.map((star,index) => (
                <img key={index} src={StarIcon} className="star-icon" alt="star_icon" />
              ))}
            </div>
            <div className="testimonial-content">
              <img src={QuoteIcon} className="quote-icon-start" alt="quote_start" />
              <p className="testimonial-text">
                {reviewtext}
             
              </p>
              <img src={QuoteIcon} className="quote-icon-end" alt="quote_end" />
            </div>
            <div className="author">
              <img
                loading="lazy"
                src={ProfilePic}
                alt="author_pic"
                className="author-image"
              />
              <span className="author-name">{author}</span>
            </div>
          </article>
        </section>
      </>
    );
  }

  function Reviews() {
 
    return (
        <div className="review-container">
            <div className="review-header">
                <h1>Vendégeink véleménye</h1>
                <img  className="pizzaiconimg" src={pizzaimg} alt="pizza_icon" loading='lazy'/>
            </div>
            <div className="review-content">
              <ReviewCard className="card" author={"Juhász Dávid"} reviewtext={"A legfinomabb pizza a városban gyors kiszállítással és kedves személyzettel :) Csak ajánlani tudom!"}/>
              <ReviewCard className="card" author={"Lénárt Kitti"} reviewtext={"Nagyon finom, és ízletes minden. Még soha nem csalódtam;bőséges adagok, kedves kiszolgálás, és szuper gyors kiszállítás!"}/>
              <ReviewCard className="card" author={"Bati Erika"} reviewtext={"Hangulatos hely nagy választékkal, ráadásul az ételérzékenyekre is odafigyelnek. :)"}/>
            </div>

        </div>
    );
  }
export default Reviews;