
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BrowserRouter as Router, Route, Link, Routes} from "react-router-dom"
import { HashRouter } from 'react-router-dom'

import LandingPage from './components/landing/LandingPage';
import MenuPage from './components/menu/MenuPage';
import jsonData from './data.json';

const loadData = () => JSON.parse(JSON.stringify(jsonData));


function App() {
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        var data = loadData();
        setMenuData(data);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuData();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className='app-content'>
          {menuData ? (
            <HashRouter>
            <Routes>
              <Route exact path="/" element={<LandingPage/>}/>
              <Route path="/menu" element={<MenuPage data={(menuData)}/>}/>
            </Routes>
            </HashRouter>
          ):(
            <p>Töltés..</p>
          )}
        
        </div>
      </header>
    </div>
  );
}

export default App;
