import React from 'react';
import "./MenuItem.css";

function MenuItem({index, itemname, ingredients, price, price_2}) {
    return ( 
        <div className='item-container'>
            <div className='item-header'>
                <h3 className='item-name'>{index}.{" "}{itemname}</h3>
                <div className="prices">
                
                    {price > 0 ?(
                        <span className="numberFont">{price} FT</span>
                    ):null}
                    
                    {price_2 > 0 ?(
                        <span className="numberFont">{price_2} FT</span>
                    ):null}
                    
                </div>
            </div>
            <div className="item-ingredients">
                <span>{" "}{ingredients}</span>
            </div>
        </div>
     )
}

export default MenuItem;