import React from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";

import { Link } from "react-router-dom";
 
const Footer = () => {

    const scrollToTarget = (event, targetId) => {
        event.preventDefault();
    
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box>
            <h1
                style={{
                    fontFamily:"raleway",
                    color: "var(--secondary)",
                    textAlign: "center",
                    marginTop: "10px",
                }}
             id="scroll-footer">
                Köszönjük, ha minket választ!
            </h1>
            <FooterContainer>
                <Row>
                    <Column>
                        <Heading>Menüpontok</Heading>
                        <FooterLink>
                            <Link to="/menu" className="nav-item" style={{color: "white"}}>Étlap</Link>
                        </FooterLink>
                        <FooterLink onClick={(e) => scrollToTarget(e, 'scroll-openhours')}>
                            Nyitvatartás
                        </FooterLink>
                        <FooterLink onClick={(e) => scrollToTarget(e, 'scroll-reviews')}>
                            Vélemények
                        </FooterLink>
                        <FooterLink href="#">
                            Főoldal
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Elérhetőségeink</Heading>
                        <FooterLink href="tel:+36203994361">
                            <span
                                style={{
                                    fontFamily:"montserrat, sans-serif"
                                }}
                            >
                            +36 20 399 4361
                            </span>
                        </FooterLink>
                        <FooterLink href="mailto:teraszpizzeria@gmail.com">
                            teraszpizzeria@gmail.com
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Közösségi oldalaink</Heading>
                        <FooterLink href="https://www.facebook.com/teraszbufepaks">
                            <i className="fab fa-facebook-f">
                                <span
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    Facebook
                                </span>
                            </i>
                        </FooterLink>
                        
                    </Column>
                </Row>
                <Row>
                <span
                    style={{
                        color: "white",
                    }}
                >
                    Copyright @ 2024 Terasz Büfé, minden jog fenntartva.
                </span>
                </Row>
            </FooterContainer>
        </Box>
    );
};
export default Footer;