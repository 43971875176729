import React, { useState, useRef, useEffect } from 'react';
import "./MobileTab.css";
import MenuItem from "../MenuItem/MenuItem"
import InfoContainer from '../InfoContainer/InfoContainer';
import Navbar from '../../landing/navbar/Navbar';

const TabNavigation = ({ categories }) => {
  const [activeTab, setActiveTab] = useState(categories[0].name);
  const tabContainerRef = useRef(null);
  const activeTabRef = useRef(null);

  useEffect(() => {
    // Scroll to active tab when it changes
    if (activeTabRef.current && tabContainerRef.current) {
      const containerWidth = tabContainerRef.current.offsetWidth;
      const activeTabLeft = activeTabRef.current.offsetLeft;
      const activeTabWidth = activeTabRef.current.offsetWidth;
      const scrollTo = (activeTabLeft + activeTabWidth / 2 - containerWidth / 2) - 10;
      tabContainerRef.current.scrollLeft = scrollTo;
    }
  }, [activeTab]);

  const handleTabClick = (name) => {
    setActiveTab(name);
  };

  return (
    <>
    <Navbar hideLogo={false}/>
    <div className="tab-navigation">
      <div className="tabs" ref={tabContainerRef}>
        {categories.map((category) => (
          <div
            key={category.name}
            className={`tab ${category.name === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(category.name)}
            ref={category.name === activeTab ? activeTabRef : null}
          >
            {category.name}
          </div>
        ))}
      </div>
      {activeTab === "Pizzák" ? (
        <>
          <InfoContainer isPizza={true}/>
          <div className="pizzaholder">
            <span className='numberFont'>30cm</span>
            <span className='numberFont'>50cm</span>
          </div>
        </>
      ):null}
      {activeTab === "Sültes tálak" ? (
        <div className="sultesholder">
          <span className='numberFont'>2 fő</span>
          <span className='numberFont'>4 fő</span>
        </div>
      ):null}
      {activeTab === "Családi menük" ? (
        <InfoContainer isPizza={false}>
          A családi menüben feltüntetett desszertről érdeklődjön a megadott telefonszámon!
        </InfoContainer>
      ):null}
      {activeTab === "Heti menük" ? (
        <InfoContainer isPizza={false}>
          A menü ára helyben fogyasztás esetén <span className='numberFont highlight' style={{fontWeight:'bold', color:'var(--primary)'}}>1950 FT</span>, kiszállítás esetén <span className='numberFont' style={{fontWeight:'bold', color:'var(--primary)'}}>2050 FT</span>
        </InfoContainer>
      ):null}
      <div className="tab-content">
        {categories.map((category) => (
          <div key={category.name} className={`tab-pane ${category.name === activeTab ? 'active' : ''}`}>
            
              {category.foods.map((food, index) => (
                <MenuItem key={index} index={index+1} itemname={food.name} ingredients={food.ingredients} price={food.price} price_2={food.price_2}/>
              ))}
            
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default TabNavigation;