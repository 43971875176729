import React, {useState} from 'react';
import "./Navbar.css";
import logo from "../../../images/logo.png";
import list from "../../../images/list.svg";
import OutsideClickHandler from "react-outside-click-handler";
import {Link} from "react-router-dom";

function Logo({ src, alt, logoinvisible }) {
    return (logoinvisible === false ? (
        <img src={src} alt={alt} className="logoNavbar" />
    ) : null);
}

function NavItem({ children }) {
    return <a href="\" className="nav-item">{children}</a>;
}

function Navbar({hideLogo}) {
    const [menuOpened, setMenuOpened] = useState(false);
    const getMenuStyles = (menuOpened) => {
        if(document.documentElement.clientWidth <= 768)
        {
            return {display: menuOpened && "flex"}
        }
    }

    const scrollToTarget = (event, targetId) => {
        event.preventDefault();
    
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navItems = ["Rólunk", "Elérhetőség", "Nyitvatartás", "Étlapunk"];

    return (
    <>
        <OutsideClickHandler
            onOutsideClick={() => {
                setMenuOpened(false);
            }}
        >
        <header className="header">
            <Logo src={logo} alt="Terasz logo" logoinvisible={hideLogo}/>
            <div className="nav-right">
                <div className="nav-list" style={getMenuStyles(menuOpened)}>
                    <Link to="/menu" className='nav-item'>Étlap</Link>
                    <a className='nav-item' href="\" onClick={(e) => scrollToTarget(e, 'scroll-footer')}>Elérhetőség</a>
                    <a className='nav-item' href="\" onClick={(e) => scrollToTarget(e, 'scroll-openhours')}>Nyitvatartás</a>
                    <a className='nav-item' href="\">Főoldal</a>
                </div>
            </div>
            <div className="nav-icon" onClick={() => setMenuOpened((prev) => !prev)}>
                    <img className="iconnavimg" src={list} alt="list"/>
                </div>
        </header>
        </OutsideClickHandler>
    </>
    );
}


export default Navbar;