import React from 'react';
import Navbar from './navbar/Navbar';
import Hero from './hero/Hero';
import Reviews from './reviews/Reviews';
import OpenHours from './openhoursandlocation/openhours';
import Menu from './menu/Menu';
import About from './about/About';
import Footer from './footer/Footer';

function LandingPage() {
    return ( 
        <>
        <Navbar hideLogo={false}/>
        <Hero/>
        <Reviews/>
        <OpenHours/>
        <Menu/>
        <About/>
        <Footer/>
        </>
     );
}

export default LandingPage;